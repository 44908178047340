import { ItemWithTranslation, SportsCompetitor } from 'types/swagger';
import React, { memo, useMemo, useState } from 'react';
import { default as classNames, default as classnames } from 'classnames';
import { isNotEmpty, purgeNulls } from 'utility/functions';

import { DynamicImage } from 'components/Image';
import { ScommessaAvvenimentoLink } from 'components/scommessaAvvenimentoLink';
import styles from './ScommesseTableCompetitors.module.scss';
import { useTranslation } from 'hooks/useLingUI';
import { SkeletonArea } from 'components/skeletonArea';

export type Competitor = {
  name?: string;
  teamImgUrl?: string;
};

export type ScommesseTableCompetitorsProps = {
  redCards?: string[] | null;
  className?: string;
  competitors: Array<SportsCompetitor | undefined>;
  isFullWidth?: boolean;
  avvenimentoKey?: string;
};

interface ScommesseTableCompetitorProps {
  redCard: string | null | undefined;
  competitor: SportsCompetitor;
}

export interface CompetitorIconProps {
  iconSrc?: string;
  className?: string;
}

export const ScommesseTableCompetitors = ({
  redCards,
  className,
  competitors,
  isFullWidth,
  avvenimentoKey,
}: ScommesseTableCompetitorsProps) => {
  const list = useMemo(
    () => (competitors ?? []).filter((x) => purgeNulls(x ?? {}) !== undefined) as Array<SportsCompetitor>,
    [competitors]
  );

  return (
    <ScommessaAvvenimentoLink
      avvenimentoKey={avvenimentoKey}
      className={classnames(styles.container, className, { [styles.full]: isFullWidth })}
    >
      <ul className={classnames(styles.list, { [styles.singleItem]: list.length === 1 })}>
        {list.map((competitor, index) => (
          <ScommesseTableCompetitor
            key={`${competitor.descrizione}_${index}`}
            competitor={competitor}
            redCard={redCards?.[index]}
          />
        ))}
      </ul>
    </ScommessaAvvenimentoLink>
  );
};

const ScommesseTableCompetitor = ({ redCard, competitor }: ScommesseTableCompetitorProps) => {
  const { t } = useTranslation();
  const { descrizione, descrizioneTrKey, iconSrc } = competitor ?? {};

  return (
    <li className={styles.item} title={descrizione}>
      {isNotEmpty(iconSrc) ? (
        <div className={styles.imageContainer} title={descrizione}>
          <CompetitorIcon iconSrc={iconSrc} className={styles.image} />
        </div>
      ) : (
        <React.Fragment key={descrizione} />
      )}
      <span className={classNames(styles.name, styles.withCard, { [styles.withCard]: redCard && redCard !== '0' })}>
        {t(descrizioneTrKey, descrizione)}
      </span>
      {redCard && redCard !== '0' && <span className={styles.redCart}>{redCard}</span>}
    </li>
  );
};

export const CompetitorIcon = memo(function MemoCompetitorIcon({
  iconSrc,
  className,
}: CompetitorIconProps): JSX.Element {
  const image = `${iconSrc ?? ''}`.trim();

  const [imgError, setImgError] = useState(false);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (imgError || !isNotEmpty(image)) return <React.Fragment />;

  return (
    <DynamicImage
      alt={''} // SIA-529
      src={image}
      width={26}
      height={26}
      loading="lazy"
      decoding="async"
      className={className}
      onError={() => setImgError(true)}
    />
  );
});

export type ScommesseTableDescriptionProps = Omit<ScommesseTableCompetitorsProps, 'redCards' | 'competitors'> &
  ItemWithTranslation<{ descrizione?: string }>;

export const ScommesseTableDescription = ({
  className,
  descrizione,
  isFullWidth,
  avvenimentoKey,
  descrizioneTrKey,
}: ScommesseTableDescriptionProps) => {
  const { t } = useTranslation();

  const text = t(descrizioneTrKey, descrizione);
  return (
    <ScommessaAvvenimentoLink
      avvenimentoKey={avvenimentoKey}
      className={classnames(styles.container, className, { [styles.full]: isFullWidth })}
    >
      <ul className={classnames(styles.list, styles.singleItem)}>
        <li className={styles.item} title={text}>
          <span className={styles.name}>{text}</span>
        </li>
      </ul>
    </ScommessaAvvenimentoLink>
  );
};
